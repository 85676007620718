<template>
  <div style="text-align: left">
    <Card style="height:100%">
      <div class="search-con search-con-top">
        <Upload
            name="files"
            :data="uploaddata"
            :headers="headers"
            :on-success="handleSearch"
            clearFiles
            multiple
            :action="uploadurl">
          <Button icon="ios-cloud-upload-outline">Upload files</Button>
        </Upload>
        <Input clearable placeholder="输入位置 搜索" class="search-input" v-model="queryinfo.path"
               @on-enter="namechange"
               @on-change="namechange"/>
        <Button @click="namechange" class="search-btn" type="primary">搜索</Button>
      </div>
      <div>
        <Table border ref="selection" :columns="columns" :data="data" ></Table>
      </div>
    </Card>
  </div>
</template>

<script>
import {get} from "@/api/http.js";
import axios from "axios";
  export default {
    name: "Files",
    data () {
      return {
        columns: [
          {type: 'selection', width: 60, align: 'center'},
          {title: '是否是文件', key: 'file'},
          {title: '文件名', key: 'name'},
        ],
        data: [],
        uploadurl: axios.defaults.baseURL + "/cron/api/v1/upload/files",
        uploaddata: {"path":"/"},
        queryinfo: {
          path: "",
        },
        headers:{'token':localStorage.getItem('token')},
      }
    },
    methods: {
      namechange () {
        this.queryinfo.path = this.queryinfo.path.trim()
        this.getdata(this.queryinfo)
        this.uploaddata["path"] = this.queryinfo.path.trim()
      },

      handleSearch() {
        this.getdata(this.queryinfo)
      },

      getdata(queryinfo) {
        console.log(axios.defaults.baseURL)
        const url = "/cron/api/v1/upload/files"
        get(url,queryinfo)
            .then((resp)=>{
              this.data = resp["data"];
            }).catch(e=>{
          return e
        })
      },
    },
    created() {
      this.getdata()
    }
  }
</script>

<style lang="less" scoped>
.search-con {
  padding: 10px 0;

  .search {
    &-col {
      display: inline-block;
      width: 200px;
    }

    &-input {
      display: inline-block;
      width: 250px;
      margin-left: 2px;
    }

    &-btn {
      margin-left: 2px;
    }
  }
}

</style>
